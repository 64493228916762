// Layout styles
.sidebar-nav > .nav-item > .nav-link:after,
.sidebar-nav > .nav-item > .nav-link[data-content]:after {
  content: "" !important;
}

#SingleMenu {
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  position: relative;
  transition: none;
}

.tab-active {
  background: #2af270;
  color: black !important;
}
.sidemenu-icons {
  width: 25px;
  margin-right: 5px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #00000d;
  background-clip: padding-box;
  border: 1px solid white;
  border-radius: 0.3rem;
  outline: 0;
}
.lText {
  padding: 1px;
  padding-left: 53px;
  color: white;
  font-size: 16px;
}
.menu {
  height: 74px;
  position: relative;
}
.pl-5, .px-5 {
  padding-left: 1.25rem !important;
}

.pr-5, .px-5 {
  padding-right: 1.25rem !important;
}
.pb-4, .py-4 {
  padding-bottom: 1rem !important;
}
.pt-4, .py-4 {
  padding-top: 1rem !important;
}
.imgs {
  position: absolute;
  height: 40px;
  width: 40px;
}
.active-menu {
  background: linear-gradient(36deg, #24C9F6, #2af270);
  color: black !important;
}
.text-unset a {
  color: inherit;
}
.text-light .nav-link {
  color: #f8f9fa;
}


body {
  font-family: Open Sans, sans-serif !important;
}

// Layout styles
.sidebar-nav > .nav-item > .nav-link:after,
.sidebar-nav > .nav-item > .nav-link[data-content]:after {
  content: "" !important;
}

.sidebar-nav-light-hover > .nav-item > .nav-link.collapser-active {
  background: none !important;
}

.sidebar-nav-light-hover > .nav-item.active > .nav-link.collapser-active {
  color: inherit !important;
}

#SingleMenu {
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  position: relative;
  transition: none;
}

.tab-active {
  background: #2af270;
  color: black !important;
}
.sidemenu-icons {
  width: 25px;
  margin-right: 5px;
}

.active-menu {
  background: linear-gradient(36deg, #24c9f6, #2af270);
  color: black !important;
}

.active-sub-menu a {
  border-color: #0cc44c !important;
  background: rgba(255, 255, 255, 0.05);
}
.text-unset a {
  color: inherit;
}
.text-light .nav-link {
  color: #f8f9fa;
}

.tcontainer {
  width: 100%;
  overflow: hidden;
  /* Hide scroll bar */
}

.mat-table {
  background: transparent !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-width: 0 !important;
}

th.mat-header-cell {
  background: #01172e !important;
  color: white !important;
  font-weight: bold !important;
}

table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: white !important;
}

.mat-paginator {
  background: #01172e !important;
}

.mat-select-value {
  color: white !important;
}

.mat-select-value {
  color: white !important;
}

.mat-select-panel {
  background: white !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black !important;
}

.mat-option {
  color: black !important;
}

.mat-option:hover {
  background: lightgray !important;
}

.mat-select-arrow {
  color: white !important;
}

.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #000000 !important;
}

.mat-sort-header-arrow {
  color: white !important;
}

tr:hover {
  background: #082326 !important;
  color: white !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2af270 !important;
}

.mat-input-element {
  caret-color: #2af270 !important;
}

.mat-dialog-container {
  background: #020113;
  border: 1px solid #54ac82;
  box-shadow: 0 10px 16px 0 #080b09, 0 6px 20px 0 rgb(45 59 46) !important;
}
.mat-cell,
.mat-footer-cell {
  color: white;
}

.panel .panel-list-sc {
  height: auto;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #2af270 !important;
  top: 25px !important;
  left: 10px !important;
}

.mat-form-field {
  line-height: 2.125 !important;
}
